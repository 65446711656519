import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material UI imports
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import AccountBox from '@material-ui/icons/AccountBox';
import Layers from '@material-ui/icons/Layers';
import ExitToApp from '@material-ui/icons/ExitToApp';

const drawerWidth = 240;

const styles = theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	toolbar: theme.mixins.toolbar,
});

function ListItemLink( props ) {
	return <ListItem button component="a" { ...props } />;
}

class MyDrawer extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
		};
	}

	componentDidMount() {
	}

	render() {
		const {
			state: {
			},
			props: {
				classes,
			},
		} = this;

		return (
			<React.Fragment>
				<CssBaseline />
				<Drawer
					className={ classes.drawer }
					variant="permanent"
					classes={ {
						paper: classes.drawerPaper,
					} }
				>
					<div className={ classes.toolbar } />
					<List component="nav">
						<ListItemLink href="/dashboard">
							<ListItemIcon><HomeIcon /></ListItemIcon>
							<ListItemText primary="Dashboard" />
						</ListItemLink>
						<ListItemLink href="/players">
							<ListItemIcon><AccountBox /></ListItemIcon>
							<ListItemText primary="Players" />
						</ListItemLink>
						<ListItemLink href="/contents">
							<ListItemIcon><Layers /></ListItemIcon>
							<ListItemText primary="Content" />
						</ListItemLink>
						<ListItemLink href="/logout">
							<ListItemIcon><ExitToApp /></ListItemIcon>
							<ListItemText primary="Logout" />
						</ListItemLink>
					</List>
				</Drawer>
			</React.Fragment>
		);
	}
}

MyDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( MyDrawer );
