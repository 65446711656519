import React from 'react';
import PropTypes from 'prop-types';

// Material UI imports
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
});

class MyAppBar extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
		};
	}

	render() {
		const {
			state: {
			},
			props: {
				classes,
			},
		} = this;

		return (
			<React.Fragment>
				<CssBaseline />
				<AppBar position="fixed" className={ classes.appBar }>
					<Toolbar>
						<Typography component="h1" variant="h6" color="inherit" noWrap>
							Wargroove Admin
						</Typography>
					</Toolbar>
				</AppBar>
			</React.Fragment>
		);
	}
}

MyAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( MyAppBar );
