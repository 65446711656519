import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material UI imports
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';

import fetchApi from '../utils/fetchApi';
import MyDrawer from '../shared/Drawer';
import MyAppBar from '../shared/AppBar';

const styles = theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
	},
	toolbar: theme.mixins.toolbar,
	tableToolbar: {
		paddingRight: theme.spacing.unit,
	},
	tableHeading: {
		flexGrow: 1,
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
});

class Players extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
			loading: true,
			players: [],
			numRows: 0,
			page: 0, // 0 indexed for table
			rowsPerPage: 10,
			platform: '',
			search: '',
			sort: '',
		};

		this.fetchApi = new fetchApi({
			'restURL': '/api/',
		});
	}

	componentDidMount = () => {
		this.getPlayers();
	}

	getPlayers = () => {
		const {
			state: {
				page,
				rowsPerPage,
				platform,
				search,
				sort,
			},
		} = this;

		const data = {
			search: search,
			platform: platform,
			results: rowsPerPage, // required
			page: page + 1, // required
			sort: sort,
		};

		this.fetchApi.post( 'players', data )
			.then( json => this.setState({
				players: json.players,
				numRows: json.num_players,
				loading: false,
			}) );
	}

	handlePlatformChange = event => {
		const value = event.target.value;
		this.setState({
			page: 0,
			platform: value,
		}, () => {
			this.getPlayers();
		});
	}

	handleSortChange = event => {
		const value = event.target.value;
		this.setState({
			page: 0,
			sort: value,
		}, () => {
			this.getPlayers();
		});
	}

	handleSearchChange = event => {
		const value = event.target.value;
		this.setState({
			page: 0,
			search: value,
		}, () => {
			this.getPlayers();
		});
	}

	handleRowClick = event => {
		const row = event.currentTarget;
		const playerId = row.dataset.id;
		window.location = '/player/' + playerId;
	}

	handleChangePage = ( event, page ) => {
		this.setState({
			page,
		}, () => {
			this.getPlayers();
		});
	};

	handleChangeRowsPerPage = event => {
		this.setState({
			rowsPerPage: parseInt( event.target.value ),
		}, () => {
			this.getPlayers();
		});
	};

	render() {
		const {
			state: {
				loading,
				players,
				numRows,
				page,
				rowsPerPage,
				platform,
				search,
				sort,
			},
			props: {
				classes,
			},
		} = this;

		const tableColumns = [
			'ID',
			'Name',
			'Platform',
			'UGC Upload Slots',
			'Matchmaking',
			'',
		];

		const platformFilter = [
			{ value: '', label: 'All' },
			{ value: 'pc', label: 'PC' },
			{ value: 'xboxlive', label: 'Xbox' },
			{ value: 'psn', label: 'Playstation' },
			{ value: 'switch', label: 'Switch' },
		];

		const sortOptions = [
			{ value: '', label: 'Default' },
			{ value: 'rating_asc', label: 'Matchmaking Rating (ASC)' },
			{ value: 'rating_desc', label: 'Matchmaking Rating (DESC)' },
		];

		if ( true === loading ) {
			return null;
		}

		return (
			<div className={ classes.root }>
				<CssBaseline />
				<MyAppBar />
				<MyDrawer />

				<main className={ classes.content }>
					<div className={ classes.toolbar } />
					<Paper>
						<Toolbar className={ classes.tableToolbar }>
							<Typography variant="h6" className={ classes.tableHeading }>
								Players
							</Typography>
							<div className={ classes.actions }>
								<FormControl className={ classes.formControl }>
									<TextField
										id="search"
										label="Search"
										name="search"
										value={ search }
										onChange={ this.handleSearchChange }
										placeholder="Search by ID, name"
									/>
								</FormControl>
								<FormControl className={ classes.formControl }>
									<InputLabel shrink htmlFor="platform-filter">
										Platform
									</InputLabel>
									<Select
										name="platform"
										value={ platform }
										input={ <Input name="platform" id="platform-filter" /> }
										native={ true }
										onChange={ this.handlePlatformChange }
									>
										{ platformFilter.map( platform => {
											return <option key={ platform.value } value={ platform.value }>{ platform.label }</option>;
										}) }
									</Select>
								</FormControl>
								<FormControl className={ classes.formControl }>
									<InputLabel shrink htmlFor="sort">
										Sort by
									</InputLabel>
									<Select
										name="sort"
										value={ sort }
										input={ <Input name="sort" id="sort-filter" /> }
										native={ true }
										onChange={ this.handleSortChange }
									>
										{ sortOptions.map( sort => {
											return <option key={ sort.value } value={ sort.value }>{ sort.label }</option>;
										}) }
									</Select>
								</FormControl>
							</div>
						</Toolbar>
						<Table className={ classes.table }>
							<TableHead>
								<TableRow>
									{ tableColumns.map( column => (
										<TableCell key={ column }>{ column }</TableCell>
									) ) }
								</TableRow>
							</TableHead>
							<TableBody>
								{ players.map( player => (
									<TableRow key={ player.id } onClick={ this.handleRowClick } data-id={ player.id }>
										<TableCell>{ player.id }</TableCell>
										<TableCell component="th" scope="row">
											{ player.name }
										</TableCell>
										<TableCell>{ player.platform }</TableCell>
										<TableCell>{ player.ugc_upload_slots }</TableCell>
										<TableCell>{ player.matchmaking_rating }</TableCell>
										<TableCell><a href={ '/player/' + player.id } style={ { 'textDecorationLine': 'none' } }>Open</a></TableCell>
									</TableRow>
								) ) }
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={ [ 10, 25, 50, 100 ] }
										count={ numRows }
										rowsPerPage={ rowsPerPage }
										page={ page }
										SelectProps={ {
											native: true,
										} }
										onChangePage={ this.handleChangePage }
										onChangeRowsPerPage={ this.handleChangeRowsPerPage }
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</Paper>
				</main>
			</div>
		);
	}
}

Players.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Players );
