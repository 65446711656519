import React from "react";
import PropTypes from "prop-types";

// Material UI imports
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import fetchApi from "../utils/fetchApi";
import MyDrawer from "../shared/Drawer";
import MyAppBar from "../shared/AppBar";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  toolbar: theme.mixins.toolbar,
  info: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing.unit * 3,
  },
  infoPaper: {
    width: "49%",
    padding: theme.spacing.unit * 3,
  },
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playersLoading: true,
      contentLoading: true,
      playerCounts: null,
      totalPlayers: 0,
      contentCounts: null,
      totalContent: 0,
    };

    this.fetchApi = new fetchApi({
      restURL: "/api/",
    });
  }

  componentDidMount() {
    this.getPlayerCount();
    this.getContentCount();
  }

  getPlayerCount = () => {
    this.fetchApi.post("players/count").then(json => {
      const totalPlayers = json.reduce((total, platform) => {
        return {count: parseInt(total.count) + parseInt(platform.count)};
      });

      this.setState({
        playerCounts: json,
        totalPlayers: totalPlayers.count,
        playersLoading: false,
      });
    });
  };

  getContentCount = () => {
    this.fetchApi.post("contents/count").then(json => {
      const totalContent = json.reduce((total, platform) => {
        return {count: parseInt(total.count) + parseInt(platform.count)};
      });

      this.setState({
        contentCounts: json,
        totalContent: totalContent.count,
        contentLoading: false,
      });
    });
  };

  render() {
    const {
      state: {
        playersLoading,
        contentLoading,
        playerCounts,
        totalPlayers,
        contentCounts,
        totalContent,
      },
      props: {classes},
    } = this;

    if (true === playersLoading || true === contentLoading) {
      return null;
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <MyAppBar />
        <MyDrawer />

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div className={classes.info}>
            <Paper className={classes.infoPaper}>
              <Typography component="h2" variant="h5">
                Players
              </Typography>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography component="h3" variant="h6">
                      Total Players
                    </Typography>
                    <Typography>{totalPlayers}</Typography>
                  </ListItemText>
                </ListItem>
                {playerCounts.map((count, index) => (
                  <ListItem key={index}>
                    <ListItemText>
                      <Typography component="h3" variant="h6">
                        Players on {count.platform}
                      </Typography>
                      <Typography>{count.count}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Paper>

            <Paper className={classes.infoPaper}>
              <Typography component="h2" variant="h5">
                Content
              </Typography>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography component="h3" variant="h6">
                      Total Content
                    </Typography>
                    <Typography>{totalContent}</Typography>
                  </ListItemText>
                </ListItem>
                {contentCounts.map((count, index) => (
                  <ListItem key={index}>
                    <ListItemText>
                      <Typography component="h3" variant="h6">
                        Content on {count.platform}
                      </Typography>
                      <Typography>{count.count}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </div>
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
