import React from 'react';
import PropTypes from 'prop-types';

// Material UI imports
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';

import fetchApi from '../utils/fetchApi';
import MyDrawer from '../shared/Drawer';
import MyAppBar from '../shared/AppBar';
import ContentInfo from './ContentInfo';

const styles = theme => ({
	root: {
		display: 'flex',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
	},
	toolbar: theme.mixins.toolbar,
	info: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing.unit * 3,
	},
	infoPaper: {
		width: '49%',
		padding: theme.spacing.unit * 3,
	},
});

class Content extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
			loading: true,
			contentId: null,
			content: null,
			status: '',
		};

		this.fetchApi = new fetchApi({
			'restURL': '/api/',
		});
	}

	componentDidMount = () => {
		const path = window.location.pathname;
		const pathParts = path.split( '/' );
		this.setState({
			contentId: parseInt( pathParts[2]),
		}, () => {
			this.getContent();
		});
	}

	getContent = () => {
		const {
			state: {
				contentId,
			},
		} = this;

		this.fetchApi.post( 'content/' + contentId )
			.then( json  => this.setState({
				content: json,
				loading: false,
				status: json.status,
			}) );
	}

	handleFeatured = () => {
		const { content, contentId } = this.state;
		const featuredFlag = 1 === parseInt( content.featured ) ? 0 : 1;

		this.fetchApi.post( 'content/' + contentId + '/featured/' + featuredFlag )
			.then( json => {
				this.getContent();
			});
	}

	handleStatus = ( event ) => {
		const { contentId } = this.state;

		this.setState({
			status: event.target.value,
		});

		const data = {
			'status': event.target.value,
		};

		this.fetchApi.post( 'content/' + contentId + '/status', data )
			.then( json => {
				this.getContent();
			});
	}

	render() {
		const {
			state: {
				loading,
				content,
				status,
			},
			props: {
				classes,
			},
		} = this;

		if ( true === loading ) {
			return null;
		}

		return (
			<div className={ classes.root }>
				<CssBaseline />
				<MyAppBar />
				<MyDrawer />

				<main className={ classes.content }>
					<div className={ classes.toolbar } />
					<ContentInfo content={ content } featuredFn={ this.handleFeatured } statusFn={ ( event ) => this.handleStatus( event ) } status={ status } />
				</main>
			</div>
		);
	}
}

Content.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Content );
