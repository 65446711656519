import React from 'react';
import PropTypes from 'prop-types';

// Material UI imports
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

const styles = theme => ({
	info: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing.unit * 3,
	},
	infoPaper: {
		width: '49%',
		padding: theme.spacing.unit * 3,
	},
	reportPaper: {
		padding: theme.spacing.unit * 3,
	},
	reportHeading: {
		marginBottom: theme.spacing.unit * 2,
	},
});

class ContentInfo extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
		};
	}

	render() {
		const {
			state: {
			},
			props: {
				classes,
				content,
				featuredFn,
				statusFn,
				status,
			},
		} = this;

		const description = JSON.parse( content.content_meta ).description;

		const statusOptions = [
			{ value: 'pending', label: 'Pending' },
			{ value: 'active', label: 'Active' },
			{ value: 'deleted', label: 'Deleted' },
			{ value: 'banned', label: 'Banned' },
		];

		return (
			<React.Fragment>
				<CssBaseline />
				<div className={ classes.info }>
					<Paper className={ classes.infoPaper }>
						<Typography component="h2" variant="h5">
							Info
						</Typography>
						<List>
							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
										ID
									</Typography>
									<Typography>
										{ content.id }
									</Typography>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
										Title
									</Typography>
									<Typography>
										{ content.content_name }
									</Typography>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
										Description
									</Typography>
									<Typography>
										{ description }
									</Typography>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
										Platform
									</Typography>
									<Typography>
										{ content.platform }
									</Typography>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
										Uploaded by
									</Typography>
									<Typography>
										{ content.player_name } - <Link href={ '/player/' + content.author_id }>View player</Link>
									</Typography>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
										Share Code
									</Typography>
									<Typography>
										{ content.share_code }
									</Typography>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
										Rating
									</Typography>
									<Typography>
										{ content.rating_score ? content.rating_score : 0 }
									</Typography>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
										Number of Downloads
									</Typography>
									<Typography>
										{ content.downloads }
									</Typography>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
										Number of Reports
									</Typography>
									<Typography>
										{ content.reports.length }
									</Typography>
								</ListItemText>
							</ListItem>
						</List>
					</Paper>

					<Paper className={ classes.infoPaper }>
						<Typography component="h2" variant="h5">
							Thumbnail
						</Typography>
						<img src={ content.thumbnail } alt="" style={ {width: '100%', maxWidth: '250px', height: 'auto', marginBottom: '40px', marginTop: '20px'} } />

						<Typography component="h2" variant="h5">
							Actions
						</Typography>
						<List>
							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
										Set Featured?
									</Typography>
									<Button
										variant="contained"
										color="primary"
										onClick={ featuredFn }
									>
										{ 0 === parseInt( content.featured ) ? 'Set as featured' : 'Remove from featured' }
									</Button>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
										Status
									</Typography>
									<Select
										name="status"
										value={ status }
										native={ true }
										onChange={ ( event ) => statusFn( event ) }
									>
										{ statusOptions.map( status => {
											return <option key={ status.value } value={ status.value }>{ status.label }</option>;
										}) }
									</Select>
								</ListItemText>
							</ListItem>
						</List>
					</Paper>
				</div>

				<Paper className={ classes.reportPaper }>
					<Typography component="h2" variant="h5" className={ classes.reportHeading }>
						Reports
					</Typography>
					{ 0 < content.reports.length ?
						<List>
							{ content.reports.map( ( report, index ) => (
								<ListItem key={ index }>
									<ListItemText>
										<Typography component="h3" variant="h6">
											{ report.reason } - reported by <a href={ '/player/' + report.player_id }>{ report.player_name }</a>
										</Typography>
										<Typography>
											{ report.description }
										</Typography>
									</ListItemText>
								</ListItem>
							) ) }
						</List> :
						<Typography>
							No reports
						</Typography>
					}
				</Paper>
			</React.Fragment>
		);
	}
}

ContentInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	content: PropTypes.object.isRequired,
	featuredFn: PropTypes.func.isRequired,
	statusFn: PropTypes.func.isRequired,
};

export default withStyles( styles )( ContentInfo );
