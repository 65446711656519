import React from "react";
import PropTypes from "prop-types";

// Material UI imports
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import withStyles from "@material-ui/core/styles/withStyles";

import fetchApi from "../utils/fetchApi";

const styles = theme => ({
  main: {
    width: 400,
    display: "block", // Fix IE 11 issue.
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  avatar: {
    //backgroundColor: '#fd921f',
    margin: theme.spacing.unit,
    borderRadius: 0,
    width: "auto",
    height: "auto",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      errorText: "",
    };

    this.fetchApi = new fetchApi({
      restURL: "/api/",
    });
  }

  handlePasswordChange(event) {
    this.setState({
      password: event.target.value,
    });
  }

  handleLogin = event => {
    event.preventDefault();

    const {
      state: {password},
    } = this;

    const data = {
      password: password,
    };

    this.fetchApi.post("login", data).then(json => {
      console.log(json);
      if (json.login) {
        location.reload();
      } else {
        this.setState({
          errorText: "Incorrect password",
        });
      }
    });
  };

  render() {
    const {
      state: {password, errorText},
      props: {classes},
    } = this;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar} src="img/caesar_roll.gif" />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} method="post">
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={event => this.handlePasswordChange(event)}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={event => this.handleLogin(event)}
            >
              Sign in
            </Button>
          </form>
        </Paper>

        {errorText ? (
          <Snackbar
            open={true}
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            message={errorText}
          />
        ) : null}
      </main>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
