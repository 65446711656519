import React from 'react';
import PropTypes from 'prop-types';

// Material UI imports
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';

import MyDrawer from '../shared/Drawer';
import MyAppBar from '../shared/AppBar';
import ContentTable from './ContentTable';

const styles = theme => ({
	root: {
		display: 'flex',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
	},
	toolbar: theme.mixins.toolbar,
});

class Contents extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
		};
	}

	componentDidMount() {
	}

	render() {
		const {
			state: {
			},
			props: {
				classes,
			},
		} = this;

		return (
			<div className={ classes.root }>
				<CssBaseline />
				<MyAppBar />
				<MyDrawer />

				<main className={ classes.content }>
					<div className={ classes.toolbar } />
					<ContentTable />
				</main>
			</div>
		);
	}
}

Contents.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Contents );
