import React from 'react';
import PropTypes from 'prop-types';

// Material UI imports
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';

import fetchApi from '../utils/fetchApi';


const styles = theme => ({
	tableHeading: {
		flexGrow: 1,
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
});

class ContentTable extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
			loading: true,
			content: null,
			numRows: 0,
			page: 0, // 0 indexed for table
			rowsPerPage: 10,
			sort: '',
			search: '',
			featured: '',
			contentType: '',
			status: '',
			platform: '',
		};

		this.fetchApi = new fetchApi({
			'restURL': '/api/',
		});
	}

	componentDidMount = () => {
		this.getContent();
	}

	getContent = () => {
		const {
			state: {
				page,
				rowsPerPage,
				sort,
				search,
				featured,
				platform,
				status,
				contentType,
			},
			props: {
				playerId,
			},
		} = this;

		const data = {
			'player_id': playerId ? playerId : null,
			results: rowsPerPage, // required
			page: page + 1, // required
			sort: sort,
			featured: featured,
			search: search,
			platform: platform,
			status: status,
			'content_type': contentType,
		};

		this.fetchApi.post( 'contents', data )
			.then( json  =>  {
				this.setState({
					content: json.rows,
					numRows: json.num_rows,
					loading: false,
				});
			});
	}

	handleRowClick = event => {
		const row = event.currentTarget;
		const contentId = row.dataset.id;
		window.location = '/content/' + contentId;
	}

	handlePlatformChange = event => {
		const value = event.target.value;
		this.setState({
			page: 0,
			platform: value,
		}, () => {
			this.getContent();
		});
	}

	handleFeaturedChange = event => {
		const value = event.target.value;
		this.setState({
			page: 0,
			featured: value,
		}, () => {
			this.getContent();
		});
	}

	handleStatusChange = event => {
		const value = event.target.value;
		this.setState({
			page: 0,
			status: value,
		}, () => {
			this.getContent();
		});
	}

	handleContentTypeChange = event => {
		const value = event.target.value;
		this.setState({
			page: 0,
			contentType: value,
		}, () => {
			this.getContent();
		});
	}

	handleSortChange = event => {
		const value = event.target.value;
		this.setState({
			page: 0,
			sort: value,
		}, () => {
			this.getContent();
		});
	}

	handleSearchChange = event => {
		const value = event.target.value;
		this.setState({
			page: 0,
			search: value,
		}, () => {
			this.getContent();
		});
	}

	handleChangePage = ( event, page ) => {
		this.setState({
			page,
		}, () => {
			this.getContent();
		});
	};

	handleChangeRowsPerPage = event => {
		this.setState({
			rowsPerPage: parseInt( event.target.value ),
		}, () => {
			this.getContent();
		});
	};

	render() {
		const {
			state: {
				loading,
				content,
				numRows,
				page,
				rowsPerPage,
				search,
				sort,
				platform,
				contentType,
				featured,
				status,
			},
			props: {
				classes,
			},
		} = this;

		if ( true === loading ) {
			return null;
		}

		const tableColumns = [
			'Thumbnail',
			'ID',
			'Title',
			'Type',
			'Share Code',
			'Platform',
			'Players',
			'Downloads',
			'Reports',
			'Rating',
			'Status',
			'Featured',
			'',
		];

		const platformFilter = [
			{ value: '', label: 'All' },
			{ value: 'pc', label: 'PC' },
			{ value: 'xboxlive', label: 'Xbox' },
			{ value: 'psn', label: 'Playstation' },
			{ value: 'switch', label: 'Switch' },
		];

		const sortOptions = [
			{ value: '', label: 'Default' },
			{ value: 'downloads', label: 'No. Downloads' },
			{ value: 'reports', label: 'No. Reports' },
			{ value: 'rating', label: 'Rating' },
		];

		const contentTypeOptions = [
			{ value: '', label: 'All' },
			{ value: 'campaign', label: 'Campaign' },
			{ value: 'puzzle', label: 'Puzzle' },
			{ value: 'scenario', label: 'Scenario' },
			{ value: 'skirmish', label: 'Skirmish' },
		];

		const featuredOptions = [
			{ value: '', label: 'All' },
			{ value: 1, label: 'Yes' },
			{ value: 0, label: 'No' },
		];

		const statusOptions = [
			{ value: '', label: 'All' },
			{ value: 'pending', label: 'Pending' },
			{ value: 'active', label: 'Active' },
			{ value: 'deleted', label: 'Deleted' },
			{ value: 'banned', label: 'Banned' },
		];

		return (
			<Paper>
				<Toolbar className={ classes.tableToolbar }>
					<Typography variant="h6" className={ classes.tableHeading }>
						User Content
					</Typography>
					<div className={ classes.actions }>
						<FormControl className={ classes.formControl }>
							<TextField
								id="search"
								label="Search"
								name="search"
								value={ search }
								onChange={ this.handleSearchChange }
								placeholder="Search by ID, name"
							/>
						</FormControl>
						<FormControl className={ classes.formControl }>
							<InputLabel shrink htmlFor="platform-filter">
								Platform
							</InputLabel>
							<Select
								name="platform"
								value={ platform }
								input={ <Input name="platform" id="platform-filter" /> }
								native={ true }
								onChange={ this.handlePlatformChange }
							>
								{ platformFilter.map( platform => {
									return <option key={ platform.value } value={ platform.value }>{ platform.label }</option>;
								}) }
							</Select>
						</FormControl>
						<FormControl className={ classes.formControl }>
							<InputLabel shrink htmlFor="featured-filter">
								Featured
							</InputLabel>
							<Select
								name="featured"
								value={ featured }
								input={ <Input name="featured" id="featured-filter" /> }
								native={ true }
								onChange={ this.handleFeaturedChange }
							>
								{ featuredOptions.map( featured => {
									return <option key={ featured.value } value={ featured.value }>{ featured.label }</option>;
								}) }
							</Select>
						</FormControl>
						<FormControl className={ classes.formControl }>
							<InputLabel shrink htmlFor="status-filter">
								Status
							</InputLabel>
							<Select
								name="status"
								value={ status }
								input={ <Input name="status" id="status-filter" /> }
								native={ true }
								onChange={ this.handleStatusChange }
							>
								{ statusOptions.map( status => {
									return <option key={ status.value } value={ status.value }>{ status.label }</option>;
								}) }
							</Select>
						</FormControl>
						<FormControl className={ classes.formControl }>
							<InputLabel shrink htmlFor="content-type-filter">
								Content Type
							</InputLabel>
							<Select
								name="content-type"
								value={ contentType }
								input={ <Input name="content-type" id="content-type-filter" /> }
								native={ true }
								onChange={ this.handleContentTypeChange }
							>
								{ contentTypeOptions.map( content => {
									return <option key={ content.value } value={ content.value }>{ content.label }</option>;
								}) }
							</Select>
						</FormControl>
						<FormControl className={ classes.formControl }>
							<InputLabel shrink htmlFor="sort">
								Sort by
							</InputLabel>
							<Select
								name="sort"
								value={ sort }
								input={ <Input name="sort" id="sort-filter" /> }
								native={ true }
								onChange={ this.handleSortChange }
							>
								{ sortOptions.map( sort => {
									return <option key={ sort.value } value={ sort.value }>{ sort.label }</option>;
								}) }
							</Select>
						</FormControl>
					</div>
				</Toolbar>
				<Table className={ classes.table }>
					<TableHead>
						<TableRow>
							{ tableColumns.map( column => (
								<TableCell key={ column }>{ column }</TableCell>
							) ) }
						</TableRow>
					</TableHead>
					<TableBody>
						{ content.map( content => (
							<TableRow key={ content.id } onClick={ this.handleRowClick } data-id={ content.id }>
								<TableCell><img src={ content.thumbnail } alt="" style={ {width: '100%', maxWidth: '50px', height: 'auto'} } /></TableCell>
								<TableCell>{ content.id }</TableCell>
								<TableCell component="th" scope="row">
									{ content.content_name }
								</TableCell>
								<TableCell>{ content.content_type }</TableCell>
								<TableCell>{ content.share_code }</TableCell>
								<TableCell>{ content.platform }</TableCell>
								<TableCell>{ content.num_players }</TableCell>
								<TableCell>{ content.num_downloads }</TableCell>
								<TableCell>{ content.num_reports }</TableCell>
								<TableCell>{ content.rating_score }</TableCell>
								<TableCell>{ content.status }</TableCell>
								<TableCell>{ content.featured }</TableCell>
								<TableCell><a href={ '/content/' + content.id } style={ { 'textDecorationLine': 'none' } }>Open</a></TableCell>
							</TableRow>
						) ) }
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={ [ 10, 25, 50, 100 ] }
								count={ numRows }
								rowsPerPage={ rowsPerPage }
								page={ page }
								SelectProps={ {
									native: true,
								} }
								onChangePage={ this.handleChangePage }
								onChangeRowsPerPage={ this.handleChangeRowsPerPage }
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</Paper>
		);
	}
}

ContentTable.propTypes = {
	classes: PropTypes.object.isRequired,
	playerId: PropTypes.number,
};

export default withStyles( styles )( ContentTable );
