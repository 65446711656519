import "@babel/polyfill";

import React from "react";
import ReactDOM from "react-dom";

import Login from "./Login/Login";
import Dashboard from "./Dashboard/Dashboard";
import Contents from "./Contents/Contents";
import Content from "./Contents/Content";
import Players from "./Players/Players";
import Player from "./Players/Player";

if (document.getElementById("view-login")) {
  ReactDOM.render(<Login />, document.getElementById("view-login"));
}

if (document.getElementById("view-dashboard")) {
  ReactDOM.render(<Dashboard />, document.getElementById("view-dashboard"));
}

if (document.getElementById("view-players")) {
  ReactDOM.render(<Players />, document.getElementById("view-players"));
}

if (document.getElementById("view-player")) {
  ReactDOM.render(<Player />, document.getElementById("view-player"));
}

if (document.getElementById("view-contents")) {
  ReactDOM.render(<Contents />, document.getElementById("view-contents"));
}

if (document.getElementById("view-content")) {
  ReactDOM.render(<Content />, document.getElementById("view-content"));
}
