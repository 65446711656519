import React from 'react';
import PropTypes from 'prop-types';

// Material UI imports
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';

import fetchApi from '../utils/fetchApi';
import MyDrawer from '../shared/Drawer';
import MyAppBar from '../shared/AppBar';
import PlayerInfo from './PlayerInfo';
import ContentTable from '../Contents/ContentTable';

const styles = theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
	},
	toolbar: theme.mixins.toolbar,
	info: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing.unit * 3,
	},
	infoPaper: {
		width: '49%',
		padding: theme.spacing.unit * 3,
	},
});

class Player extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
			loading: true,
			playerId: null,
			player: null,
			numRows: 0,
			page: 0, // 0 indexed for table
			rowsPerPage: 10,
		};

		this.fetchApi = new fetchApi({
			'restURL': '/api/',
		});
	}

	componentDidMount = () => {
		const path = window.location.pathname;
		const pathParts = path.split( '/' );
		this.setState({
			playerId: parseInt( pathParts[2]),
		}, () => {
			this.getPlayer();
		});
	}

	getPlayer = () => {
		const {
			state: {
				playerId,
				page,
				rowsPerPage,
			},
		} = this;

		const data = {
			results: rowsPerPage, // required
			page: page + 1, // required
		};

		this.fetchApi.post( 'player/' + playerId, data )
			.then( json  => this.setState({
				player: json,
				numRows: json.num_content,
				loading: false,
			}) );
	}

	handleClearUgcUploadSlots = () => {
		const { playerId } = this.state;

		this.fetchApi.post( 'player/' + playerId + '/ugc/delete' )
			.then( json => {
				this.getPlayer();
			});
	}

	handleUpdateUgcUploadSlots = ( ugcUploadSlots ) => {
		const { playerId } = this.state;

		this.fetchApi.post( 'player/' + playerId + '/ugc/' + ugcUploadSlots )
			.then( json => {
				this.getPlayer();
			});
	}

	render() {
		const {
			state: {
				loading,
				playerId,
				player,
			},
			props: {
				classes,
			},
		} = this;

		if ( true === loading ) {
			return null;
		}

		return (
			<div className={ classes.root }>
				<CssBaseline />
				<MyAppBar />
				<MyDrawer />

				<main className={ classes.content }>
					<div className={ classes.toolbar } />
					<PlayerInfo player={ player } handleClearUgcUploadSlots={ this.handleClearUgcUploadSlots } handleUpdateUgcUploadSlots={ this.handleUpdateUgcUploadSlots } />
					<ContentTable playerId={ playerId } />
				</main>
			</div>
		);
	}
}

Player.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Player );
