import React from 'react';
import PropTypes from 'prop-types';

// Material UI imports
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const styles = theme => ({
	info: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing.unit * 3,
	},
	infoPaper: {
		width: '49%',
		padding: theme.spacing.unit * 3,
	},
	actionButton: {
		marginRight: '10px',
	},
});

class PlayerInfo extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
			ugcUploadSlots: props.player.ugc_upload_slots,
		};
	}

	handleUgcUploadSlotsChange = event => {
		const value = event.target.value;
		this.setState({
			ugcUploadSlots: value,
		});
	}

	handleClearUgcUploadSlots = () => {
		this.setState({
			ugcUploadSlots: null,
		});

		this.props.handleClearUgcUploadSlots();
	}

	render() {
		const {
			state: {
				ugcUploadSlots,
			},
			props: {
				classes,
				player,
				handleClearUgcUploadSlots,
				handleUpdateUgcUploadSlots,
			},
		} = this;

		return (
			<React.Fragment>
				<CssBaseline />
				<div className={ classes.info }>
					<Paper className={ classes.infoPaper }>
						<Typography component="h2" variant="h5">
								Info
						</Typography>
						<List>
							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
											ID
									</Typography>
									<Typography>
										{ player.id }
									</Typography>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
											Username
									</Typography>
									<Typography>
										{ player.name }
									</Typography>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
											Platform
									</Typography>
									<Typography>
										{ player.platform }
									</Typography>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
											Allow UGC?
									</Typography>
									<Typography>
										{ 1 === parseInt( player.allow_ugc ) ? 'Yes' : 'No' }
									</Typography>
								</ListItemText>
							</ListItem>

							<ListItem>
								<ListItemText>
									<Typography component="h3" variant="h6">
											Reports
									</Typography>
									<Typography>
										{ player.reports.length }
									</Typography>
								</ListItemText>
							</ListItem>
						</List>
					</Paper>

					<Paper className={ classes.infoPaper }>
						<Typography component="h2" variant="h5">
								Actions
						</Typography>
						<List>
							<ListItem>
								<ListItemText>
									<FormControl className={ classes.formControl }>
										<TextField
											id="ugc_upload_slots"
											label="UGC Upload Slots"
											name="ugc_upload_slots"
											type="number"
											step="1"
											value={ ugcUploadSlots ? ugcUploadSlots : '' }
											onChange={ this.handleUgcUploadSlotsChange }
										/>
										<FormHelperText>If value is empty, default will be used</FormHelperText>
									</FormControl><br /><br />
									<Button
										className={ classes.actionButton }
										variant="contained"
										color="primary"
										onClick={ () => handleUpdateUgcUploadSlots( ugcUploadSlots ) }
									>
										Update
									</Button>
									<Button
										className={ classes.actionButton }
										variant="contained"
										onClick={ this.handleClearUgcUploadSlots }
									>
										Use Default
									</Button>
								</ListItemText>
							</ListItem>
						</List>
					</Paper>
				</div>
			</React.Fragment>
		);
	}
}

PlayerInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	player: PropTypes.object.isRequired,
	handleClearUgcUploadSlots: PropTypes.func.isRequired,
	handleUpdateUgcUploadSlots: PropTypes.func.isRequired,
};

export default withStyles( styles )( PlayerInfo );
